import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';
import Nuage from '@oxeva/nuage-sdk';

import { useForm } from 'react-hook-form';
import { ComplexButton, ErrorMessage } from '@nuage/ui-components';

// Organisms
import FormLanding from '@molecules/group/formLanding';
import DetailsCoupon from '@molecules/group/detailsCoupon';
import MainLayout from '@organisms/main/mainLayout';
import Footer from '@organisms/main/footer';

import ComplexField from '@molecules/interactive/complexField';

// Illustrations
import Referral from '@images/svg/referral.svg';

import { dispatchGtagEvent } from '@utils/eventsManager';
import lang from '@utils/lang';
import NuageClient from '@utils/client';

import './sponsorship.scss';

const SponsorshipPage = ({ params }) => {
    const { _string } = lang;

    if (params.id === '[id]') { navigate('/'); }

    const timer = useRef(null);

    const {
        handleSubmit,
        control,
        formState: { errors },
        // setError,
        // clearErrors,
        // trigger,
    } = useForm({
        mode: 'onTouched',
    });

    const [clObj, setClObj] = useState(null);
    const [sponsorship, setSponsorship] = useState({});
    const [referalLoading, setReferalLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorType, setErrorType] = useState(null);

    const [validMail, setValidMail] = useState(null);

    const sponsorNoErrors = sponsorship && sponsorship !== 'error';

    const onSignin = useCallback(({ mail }) => {
        if (!clObj) { return; }

        if (!clObj || !sponsorship) { return; }
        clearTimeout(timer.current);
        setLoading(true);

        if (!clObj || !sponsorship) { return; }

        const invitation = new Nuage.Invitation({
            email: mail,
        });

        if (sponsorship?.id) {
            invitation.sponsor = sponsorship.id;
        }

        clObj.persist(invitation).onReady((errorInvitation) => {
            if (errorInvitation) {
                setLoading(false);
                switch (errorInvitation.status) {
                case 429: {
                    setErrorType('requestError');
                    break;
                }
                case 422: {
                    setErrorType('forbidden');
                    break;
                }
                default: {
                    setErrorType('alert');
                    break;
                }
                }
                timer.current = setTimeout(() => {
                    setErrorType(null);
                }, 3000);
            } else {
                setValidMail(mail);
                setSuccess(true);
                dispatchGtagEvent('submit', {
                    event_category: 'form',
                    event_label: 'compte',
                });
            }
        });
    }, [sponsorship, clObj]);

    useEffect(() => {
        if (!clObj) { return; }

        // To be reactivated when ready
        clObj.checkSponsorship({ id: params.id }).onReady((error, data) => {
            if (error) {
                setSponsorship('error');
                setReferalLoading(false);
            } else {
                setSponsorship(data);
                setReferalLoading(false);
            }
        });
    }, [params, clObj]);

    useEffect(() => {
        const newClObj = new NuageClient();
        setClObj(newClObj.cl);
    }, []);

    return (
        <MainLayout
            title={sponsorNoErrors ? _string('sponsorship_titleTab', {
                referree: sponsorship.sponsor || '',
            }) : _string('sponsorship_generic_titleTab')}
            socials={_string('sponsorship_socials')}
            footer={false}
            classTitle="sponsorship"
        >
            <FormLanding
                title={sponsorNoErrors ? _string('sponsorship_title', {
                    referree: sponsorship.sponsor,
                }) : _string('sponsorship_generic_title')}
                titleForm={sponsorNoErrors ? _string('sponsorship_description', {
                    money: sponsorship.value,
                }) : _string('sponsorship_generic_description')}
                confirmTitle={sponsorNoErrors ? _string('sponsorship_confirm_title') : _string('sponsorship_generic_confirm_title')}
                confirmText={sponsorNoErrors ? _string('sponsorship_confirm_text', { mail: validMail }) : _string('sponsorship_generic_confirm_text', { mail: validMail })}
                infoForm={sponsorNoErrors ? _string('sponsorship_form_info') : _string('sponsorship_form_generic_info')}
                loading={referalLoading}
                success={success}
                onSubmit={handleSubmit(onSignin)}
                image={
                    <Referral />
                }
            >
                <div className="sponsorship__field">
                    <ComplexField
                        name="mail"
                        id="mail"
                        rules={{
                            required: _string('sponsorship_error_mail_invalid'),
                            pattern: {
                                // Official regex, do not need eslint
                                // eslint-disable-next-line no-control-regex
                                value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                                message: _string('sponsorship_error_mail_invalid'),
                            },
                        }}
                        controlForm={control}
                        placeholder={_string('sponsorship_form_mail_placeholder')}
                        noValidStateField
                        disabled={loading}
                    />
                </div>
                { errorType && (
                    <ErrorMessage opened>
                        {_string(`intro_${errorType}`)}
                    </ErrorMessage>
                )}
                <ComplexButton
                    onClick={handleSubmit(onSignin)}
                    disabled={Object.keys(errors).length !== 0 || errorType || loading}
                    type="submit"
                    color="secondary"
                    // size="large"
                    loading={loading}
                >
                    {_string('sponsorship_form_btn')}
                </ComplexButton>
            </FormLanding>
            <div className="sponsorship__info">
                <DetailsCoupon />
            </div>
            <Footer />
        </MainLayout>
    );
};

SponsorshipPage.propTypes = {
    params: PropTypes.objectOf(PropTypes.string),
};

SponsorshipPage.defaultProps = {
    params: null,
};

export default SponsorshipPage;
